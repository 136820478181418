import React from "react";
import moment from "moment-timezone";
import { site } from "./config/config";

import "cleave.js/dist/addons/cleave-phone.us.js";
import Cleave from "cleave.js/react";

import { addQueryFieldToForm } from "./helpers/form_helper";

Object.defineProperty(String.prototype, "makeName", {
  value() {
    return this.toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-");
  },
});
const phone =
  /^[\+]?([0-9][-\s\.])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;
const date = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/g;
const email = /^.+@.+\..+$/g;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      data: [],
      show: [],
      show_login: false,
      show_reset_password: false,
      show_set_password: false,
      account_id: null,
      reset_password_success: false,
      set_password_success: false,
      error_fields: [],
      login_errors: "",
      step: 0,
      server_error: false,
      loading: false,
      redirect: null, //errors: props.page_error ? [{field: null, error: props.page_error}] : []
    };
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.showStep = this.showStep.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);
    this.check = this.check.bind(this);
    this.login = this.login.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.showSetPassword = this.showSetPassword.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.showResetPassword = this.showResetPassword.bind(this);
    this.resetPasswordSuccess = this.resetPasswordSuccess.bind(this);
    this.hideLogin = this.hideLogin.bind(this);
    this.processLogin = this.processLogin.bind(this);
    this.processResetPassword = this.processResetPassword.bind(this);
    this.processSetPassword = this.processSetPassword.bind(this);
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.passwordAgainRef = React.createRef();
    this.validateField = this.validateField.bind(this);
  }
  login() {
    let errors, success;
    if (this.state.login_errors && this.state.login_errors.trim() !== "") {
      errors = <div className="errors">{this.state.login_errors}</div>;
    }
    if (this.state.set_password_success) {
      success = (
        <div className="success">
          Your password was set successfully, please login.
        </div>
      );
    }

    return (
      <div className="page form">
        <div className="errors-wrapper">
          {success}
          {errors}
        </div>
        <h1 id="title">Log in to your patient account</h1>
        <label>Email Address</label>
        <input type="email" ref={this.emailRef} required />
        <label>Password</label>
        <input type="password" ref={this.passwordRef} required />
        <a className="btn prev" onClick={this.hideLogin}>
          Cancel
        </a>
        <a
          className={`btn next${this.state.loading ? " disabled" : ""}`}
          onClick={this.processLogin}
        >
          {this.state.loading ? "Loading..." : "Submit"}
        </a>
        <br />
        <br />
        <a
          href="#forgot_password"
          class="form-link"
          onClick={this.showResetPassword}
        >
          Forgot your password?
        </a>
      </div>
    );
  }
  setPassword() {
    let errors, errors_text;
    if (this.state.login_errors && this.state.login_errors.trim() !== "") {
      errors_text = this.state.login_errors;
      if (errors_text === "reset_password_again") {
        errors_text = (
          <span>
            Your login link has expired, please reset your password again{" "}
            <a onClick={this.showResetPassword} href="/#forgot_password">
              here
            </a>
            .
          </span>
        );
      }
      errors = <div className="errors">{errors_text}</div>;
    }

    return (
      <div className="page form">
        <div className="errors-wrapper">{errors}</div>
        <h1 id="title">Set a new password</h1>
        <label>Password</label>
        <input
          key="100099"
          name="create-password"
          type="password"
          ref={this.passwordRef}
          required
        />
        <label>Password Again</label>
        <input
          key="100098"
          name="create-password-1"
          type="password"
          ref={this.passwordAgainRef}
          required
        />
        <a className="btn prev" onClick={this.hideLogin}>
          Cancel
        </a>
        <a
          className={`btn next${this.state.loading ? " disabled" : ""}`}
          onClick={this.processSetPassword}
        >
          {this.state.loading ? "Loading..." : "Submit"}
        </a>
      </div>
    );
  }
  resetPassword() {
    let errors;
    if (this.state.login_errors && this.state.login_errors.trim() !== "") {
      errors = <div className="errors">{this.state.login_errors}</div>;
    }
    return (
      <div className="page form">
        <div className="errors-wrapper">{errors}</div>
        <h1 id="title">Forgot your password?</h1>
        <p>
          Enter your email address below, and we will send you a link to reset
          your password.
        </p>
        <label>Email Address</label>
        <input type="email" ref={this.emailRef} required />
        <a className="btn prev" onClick={this.showLogin}>
          Back to Login
        </a>
        <a
          className={`btn next${this.state.loading ? " disabled" : ""}`}
          onClick={this.processResetPassword}
        >
          {this.state.loading ? "Loading..." : "Submit"}
        </a>
      </div>
    );
  }
  resetPasswordSuccess() {
    return (
      <div className="page form">
        <h1 id="title">Password reset successfully.</h1>
        <p>
          You will receive an email in a few minutes with a link to reset your
          password. Please check your spam folder as well.
        </p>
        <p>The link to reset your password will be valid for 15 minutes.</p>
        <a className="btn prev" onClick={this.showLogin}>
          Back to Login
        </a>
      </div>
    );
  }
  hideLogin() {
    window.location.href = "/";
  }
  processLogin() {
    let emailValue = this.emailRef.current.value,
      password = this.passwordRef.current.value,
      that = this,
      errors = [];
    if (!emailValue || emailValue.trim() === "" || !emailValue.match(email)) {
      errors.push("Please enter your email address");
    }
    if (!password || password.trim() === "") {
      errors.push("Please enter your password");
    }
    if (errors.length > 0) {
      return this.setState({
        set_password_success: false,
        login_errors: errors.join("\n"),
      });
    }

    fetch(window.server_url + "/login_patient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: emailValue.trim().toLowerCase(), //.filter(Boolean),
        password: password,
        // patient_id: localStorage.getItem('patient_id')
      }),
    })
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
          return that.setState({ server_error: true, loading: false });
        }
        return response.json();
      })
      .then(function (res) {
        console.log(res);
        if (!res.valid) {
          return that.setState({
            login_errors:
              "The email address and password you entered did not match, please try again",
            loading: false,
          });
        }
        if (res.error || !res.redirect) {
          return that.setState({ server_error: true, loading: false });
        }
        if (res.patient_id) localStorage.setItem("patient_id", res.patient_id);
        if (res.account_id) localStorage.setItem("account_id", res.account_id);

        document.getElementById("title").scrollIntoView();

        if (res.redirect.indexOf("https") === -1) {
          that.loadForm(res.redirect);
        } else {
          localStorage.clear();
          window.location.href = res.redirect;
        }
      })
      .catch((error) => console.log(error));

    this.setState({ loading: true });
  }
  processResetPassword() {
    let emailValue = this.emailRef.current.value,
      that = this,
      errors = [];
    if (!emailValue || emailValue.trim() === "" || !emailValue.match(email)) {
      errors.push("Please enter your email address");
    }
    if (errors.length > 0) {
      return this.setState({ login_errors: errors.join("\n") });
    }

    fetch(window.server_url + "/reset_patient_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: emailValue.trim().toLowerCase(),
      }),
    })
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(function (res) {
        console.log(res);
        if (res.error) {
          return that.setState({ server_error: true, loading: false });
        }
        if (res.valid === false) {
          return that.setState({
            loading: false,
            login_errors:
              "We couldn't find an account with that email address, please check the email address and try again.",
          });
        }
        if (res.valid === true && res.success === false) {
          return that.setState({
            loading: false,
            login_errors:
              "There was an error sending the password reset email. Please try again in a few minutes or contact us directly at (877) 583-5633.",
          });
        }
        if (res.success) {
          that.setState({
            loading: false,
            reset_password_success: true,
            show_reset_password: false,
          });
        }
      })
      .catch((error) => console.log(error));

    this.setState({ loading: true });
  }
  processSetPassword() {
    let passwordValue = this.passwordRef.current.value,
      passwordAgainValue = this.passwordAgainRef.current.value,
      that = this,
      errors = [];

    // todo: add password validation here
    if (!passwordValue || passwordValue.trim() === "") {
      errors.push("Please enter a new password.");
    }
    if (passwordValue !== passwordAgainValue) {
      errors.push("Please enter the same password again.");
    }
    if (
      !(
        passwordValue.length > 7 &&
        passwordValue.match(/[0-9]/g) !== null &&
        passwordValue.match(/[A-Z]/g) !== null
      )
    ) {
      errors.push(
        "Password must be at least 8 characters long and include a number and uppercase letter."
      );
    }
    if (errors.length > 0) {
      return this.setState({ login_errors: errors.join("\n") });
    }

    fetch(window.server_url + "/set_patient_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("password-reset-token"),
        password: passwordValue,
      }),
    })
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(function (res) {
        console.log(res);
        if (res.valid === false && !res.error) {
          return that.setState({
            login_errors: "There was an error setting your password",
          });
        }
        if (res.error) {
          return that.setState({
            server_error: true,
            login_errors: res.error,
            loading: false,
          });
        }
        if (res.success) {
          localStorage.removeItem("password-reset-token");
          window.location.hash = "#login";
          that.setState({
            loading: false,
            login_errors: null,
            show_login: true,
            set_password_success: true,
            show_set_password: false,
          });
        }
      })
      .catch((error) => console.log(error));

    this.setState({ loading: true });
  }
  showLogin() {
    this.setState({
      show_login: true,
      show_set_password: false,
      show_reset_password: false,
      loading: false,
      login_errors: null,
      reset_password_success: false,
    });
  }
  showResetPassword() {
    this.setState({
      show_login: false,
      show_set_password: false,
      show_reset_password: true,
      set_password_success: false,
      login_errors: null,
      loading: false,
    });
  }
  showSetPassword() {
    this.setState({
      show_login: false,
      show_reset_password: false,
      show_set_password: true,
      set_password_success: false,
      login_errors: null,
      loading: false,
    });
  }
  submit() {
    let that = this,
      redirect = this.state.redirect,
      redirect_switch = this.state.redirect_switch;

    if (!redirect) redirect = that.state.form.redirect;
    if (redirect_switch && redirect === "switch") redirect = redirect_switch;

    // document.getElementById('title').scrollIntoView();
    // return that.loadForm(redirect);

    let data = that.state.data;
    addQueryFieldToForm(data);

    fetch(window.server_url + "/intake", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        data, //.filter(Boolean),
        form_id: that.state.form_id,
        next_form: redirect,
        patient_id: localStorage.getItem("patient_id"),
      }),
    })
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(function (res) {
        if (res.error) {
          if (
            res.error_info &&
            res.error_info.code &&
            res.error_info.code === 11000 &&
            res.error_info.keyPattern.email === 1
          ) {
            return that.setState({ server_error: "login", loading: false });
          } else {
            return that.setState({ server_error: true, loading: false });
          }
        }
        if (res.patient_id) localStorage.setItem("patient_id", res.patient_id);
        if (res.account_id) localStorage.setItem("account_id", res.account_id);
        document.getElementById("title").scrollIntoView();
        if (redirect.indexOf("https") === -1) {
          that.loadForm(redirect);
        } else {
          localStorage.clear();
          window.location.href = redirect;
        }
      })
      .catch((error) => console.log(error));
    this.setState({ loading: true });
  }
  getField(form, field) {
    let match;

    if (!form) return "None";

    match = form.find((e) => e && e.name === field);
    if (match && match.value && match.value !== false) return match.value;
    return "";
  }
  loadForm(form) {
    var that = this;
    if (form === "none" && localStorage.getItem("emr-price") === "membership") {
      form = "regform-membership";
    }
    if (form === "none" && localStorage.getItem("emr-price") === "wlc") {
      form = "regform-post-op-1";
    }
    if (form === "none" && localStorage.getItem("emr-price") === "chronic-pain") {
      form = "regform-chronic-pain-1";
    }
    if (form === "none" && localStorage.getItem("emr-price") === "headache") {
      form = "regform-headache-1";
    }
    if (form === "none" && localStorage.getItem("emr-price") === "ora") {
      form = "regform-scs-1";
    }
    if (
      form === "regform-bariatric-counseling" &&
      localStorage.getItem("emr-price") === "wlc"
    ) {
      form = "regform-insurance";
    }

    let account_id;
    if (localStorage.getItem("account_id")) {
      account_id = localStorage.getItem("account_id");
    }
    if (form === "done") {
      that.setState({
        loading: false,
        form: {},
        form_id: form,
        server_error: false,
        data: [],
        show: [],
        account_id: account_id,
        show_login: false,
        login_errors: false,
        set_password_success: false,
        error_fields: [],
        step: 0,
        redirect: null,
      });
    } else {
      fetch("/forms/" + form + ".min.json")
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
            localStorage.clear();
            window.location.href = "/";
          }
          return response.json();
        })
        .then(async function (form_shape) {
          let new_state = {
            loading: false,
            form: form_shape,
            form_id: form,
            server_error: false,
            data: [],
            show_login: false,
            set_password_success: false,
            login_errors: false,
            show: [],
            account_id: account_id,
            error_fields: [],
            step: 0,
            redirect: form_shape.redirect,
          };
          document.title = window.base_title + " - " + form_shape.title;

          let _form = form;
          let patient;

          if (localStorage.getItem("patient_id")) {
            const patient_res = await fetch(
              window.server_url +
                "/patients/" +
                localStorage.getItem("patient_id"),
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              }
            )
            patient = await patient_res.json();
            
          }
          
          let data = [];
          let form_data;

          if (patient && patient.data && patient.data.form && patient.data.form[_form]) {
            form_data = patient.data.form[_form];
          }
          // console.log('if == ', patient.data.form, _form, form_data);

          form_shape.steps.forEach((step, step_index) => {
            step.parts[0].blocks.forEach((block, __index) => {
              let value = "";
              let title = that.blockTitle(block);
              let index = that.stepIndex(step_index, __index);

              // console.log('field == ', title);
              if (form_data) value = that.getField(form_data, title);
              // console.log('form-data == ', value);

              // if(multi) {
              //   if(!data[index]) data[index] = {name: name, value: []};
              //   if(new_value) {
              //     data[index].value.push(value);
              //   } else {
              //     _find = data[index].value.indexOf(value);
              //     data[index].value.splice(_find, 1);
              //   }

              // }
              if(localStorage.getItem("emr-price") === "ora" && title === 'Procedure') {
                value = 'Opioid Risk Assessment';
              }

              if (
                value &&
                typeof value === "string" &&
                value.indexOf("redirect") > -1
              ) {
                let redirect = value
                  .split("::")[0]
                  .replace("redirect:", "");
                new_state.redirect = redirect;
              }


              console.log('updating...', block,
                title,
                __index,
                value,
                false,
                null,
                null,
                data);

              data = that.update(
                block,
                title,
                __index,
                value,
                false,
                null,
                null,
                data
              );
            });
          });

          new_state.data = data;

          that.setState(new_state);
        })
        .catch((error) => console.log(error));     
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.form_id !== "done" && this.state.form_id == "done") {
      fetch(window.server_url + "/mark_completed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          patient_id: localStorage.getItem("patient_id"),
        }),
      })
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
            //that.setState(new_state);
          }
          return response.json();
        })
        .then(function (res) {
          window.location.href = "/";
        });
    }
  }
  componentDidMount() {
    if (window.location.hash === "#forgot_password") this.showResetPassword();
    else if (
      window.location.hash === "#password_reset" &&
      localStorage.getItem("password-reset-token")
    )
      this.showSetPassword();
    else if (window.location.hash === "#login") this.showLogin();
    else this.loadForm(this.props.form);
  }
  validateField(field, data) {
    let errors = [];
    let that = this;
    let visible = true;
    if (field.conditional_group)
      field.conditional_group.split(",").forEach(function (condition) {
        if (!that.state.show[condition]) visible = false;
      });
    let required = field.required === "true";
    if (
      localStorage.getItem("emr-price") === "membership" &&
      field.member_hide === "true"
    ) {
      required = false;
    }
    let weak_password =
      field.type === "password" &&
      data &&
      !(
        data.value.length > 7 &&
        data.value.match(/[0-9]/g) !== null &&
        data.value.match(/[A-Z]/g) !== null
      );

    if (
      ["wlc", "chronic-pain", "headache", "ora"].indexOf(
        localStorage.getItem("emr-price")
      ) > -1 &&
      field.price_hide === "true"
    ) {
      required = false;
    }
    if (
      field.only_for_price &&
      localStorage.getItem("emr-price") !== field.only_for_price
    ) {
      required = false;
    }
    let no_value =
      !data ||
      (typeof data.value === "array" && data.value.length === 0) ||
      (typeof data.value === "string" && data.value.trim() === "");
    let email_mismatch =
      field.validate === "email" && (no_value || !data.value.match(email));
    let phone_mismatch =
      field.validate === "phone" && (no_value || !data.value.match(phone));
    let past_mismatch =
      field.validate === "past" &&
      (no_value || !moment(data.value).isBefore(moment()));
    let date_mismatch = field.type === "date" && (no_value || !moment(date));

    let match_invalid;
    if (field.matches && data.value) {
      let others = document.getElementsByClassName("match-" + field.matches);
      Array.prototype.forEach.call(others, (element) => {
        let value = data.value;
        if (field.type === "date") {
          value = moment(value).format("MM/DD/YYYY");
        }
        if (typeof element.value !== "undefined" && element.value !== value)
          match_invalid = true;
      });
    }

    if (required && visible && no_value) {
      errors.push("missing");
    }
    if (required && visible && weak_password) {
      errors.push("password");
    }
    if (required && visible && phone_mismatch) {
      errors.push("phone");
    }
    if (required && visible && match_invalid) {
      errors.push("match");
    }
    // if(match_required && match_invalid) {
    //   errors.push('match');
    // }
    if (required && visible && date_mismatch) {
      errors.push("date");
    }
    if (required && visible && past_mismatch) {
      errors.push("past");
    }
    if (email_mismatch) {
      errors.push("email");
    }
    return errors.length ? errors : null;
  }
  stepIndex(step, index) {
    return step * 1000 + index;
  }
  check() {
    let step = this.state.form.steps[this.state.step].parts[0],
      that = this,
      error_fields = [],
      name;

    step.blocks.forEach(function (field, __index) {
      let index = that.stepIndex(that.state.step, __index),
        data = that.state.data[index];

      error_fields[index] = that.validateField(field, data);
    });
    if (error_fields.filter(Boolean).length) {
      this.setState({
        error_fields: error_fields,
      });
      window.setTimeout(function () {
        var error = document.getElementsByClassName("error");
        if (error[0]) error[0].scrollIntoView();
      }, 50);
      return false;
    }
    return true;
  }
  blockTitle(block) {
    let header;
    let first = block.elements ? block.elements[0] : false;
    if (first && first.type === "header")
      header = block.elements[0].value.trim();
    else header = block.label;

    if (!header || typeof header === "undefined") header = "";
    return header;
  }
  prev() {
    let to = this.state.step - 1;
    if (to < 0 && this.state.form.prev) {
      return this.loadForm(this.state.form.prev);
    }
    //if(!this.check()) return;
    this.setState({
      step: to,
    });
    document.getElementById("title").scrollIntoView();
  }
  next() {
    let to = this.state.step + 1;
    if (!this.check()) return;
    if (to > this.state.form.steps.length - 1) {
      return this.submit();
    }
    this.setState({
      step: to,
    });
    document.getElementById("title").scrollIntoView();
  }
  render() {
    if (this.state.reset_password_success) return this.resetPasswordSuccess();
    if (this.state.show_set_password) return this.setPassword();
    if (this.state.show_reset_password) return this.resetPassword();
    if (this.state.show_login) return this.login();
    if (!this.state.form.steps) return false;
    let step = this.state.form.steps[this.state.step].parts[0];
    let optional =
      this.state.form.steps.length > 1 ? (
        <span className="optional">
          {" "}
          - step {this.state.step + 1} of {this.state.form.steps.length}
        </span>
      ) : (
        ""
      );

    let errors = [];

    if (this.state.error_fields.filter(Boolean).length) {
      if (this.state.error_fields.find((e) => e && e.indexOf("missing") > -1))
        errors.push(
          <div className="errors">
            Please complete the highlighted fields before going to the next
            step.
          </div>
        );
      if (this.state.error_fields.find((e) => e && e.indexOf("phone") > -1))
        errors.push(
          <div className="errors">Please enter a valid phone number.</div>
        );
      if (this.state.error_fields.find((e) => e && e.indexOf("password") > -1))
        errors.push(
          <div className="errors">
            Password must be at least 8 characters long and include a number and
            uppercase letter.
          </div>
        );
      if (this.state.error_fields.find((e) => e && e.indexOf("match") > -1))
        errors.push(<div className="errors">Fields must match.</div>);
      if (this.state.error_fields.find((e) => e && e.indexOf("date") > -1))
        errors.push(
          <div className="errors">Please enter a valid date (mm/dd/yyyy).</div>
        );
      if (this.state.error_fields.find((e) => e && e.indexOf("email") > -1))
        errors.push(
          <div className="errors">Please enter a valid email address.</div>
        );
      if (this.state.error_fields.find((e) => e && e.indexOf("past") > -1))
        errors.push(
          <div className="errors">Please enter a valid birthday.</div>
        );
    }
    let login = "";
    if (
      this.state.step === 0 &&
      !this.state.account_id &&
      localStorage.getItem("emr-price") === "wlc"
    ) {
      login = (
        <a
          href="#login"
          className="login-link form-link"
          onClick={this.showLogin}
        >
          Returning counseling patient? Log in here
        </a>
      );
    }
    if (this.state.server_error) {
      if (this.state.server_error === true)
        errors.push(
          <div className="errors">
            There was an error contacting the server. Please try again in a few
            minutes or contact us directly at (877) 583-5633.
          </div>
        );

      if (this.state.server_error === "login")
        errors.push(
          <div className="errors">
            An account already exists with the email address you entered. Please
            login{" "}
            <a href="#login" onClick={this.showLogin}>
              here
            </a>{" "}
            as a returning patient.
          </div>
        );
    }
    return (
      <div className="page form">
        <div className="errors-wrapper">{errors}</div>
        <h1 id="title">
          {this.state.form.title}
          {optional}
        </h1>
        {login}
        {this.showStep(step)}
        {this.state.step > 0 || (this.state.form && this.state.form.prev) ? (
          <a className="btn prev" onClick={this.prev}>
            Previous Step
          </a>
        ) : (
          ""
        )}
        <a
          className={`btn next${this.state.loading ? " disabled" : ""}`}
          onClick={this.next}
        >
          {this.state.loading ? "Loading..." : "Next Step"}
        </a>
        {site.allow_restart && (this.state.form.prev || this.state.form.final)
          ? [
              <br />,
              <a
                className="btn margin-top"
                onClick={(e) => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Start Over
              </a>,
            ]
          : null}
      </div>
    );
  }
  sanitize(input) {
    var output = "";
    for (var i = 0; i < input.length; i++) {
      if (
        input &&
        typeof input.charCodeAt === "function" &&
        input.charCodeAt(i) <= 127
      ) {
        output += input.charAt(i);
      }
    }
    return output;
  }
  update(
    field,
    name,
    index,
    value,
    multi = false,
    new_value = null,
    actions = null,
    existing_data = null
  ) {
    let data;

    value = this.sanitize(value);

    if (existing_data) data = existing_data;
    else data = Object.assign([], this.state.data);

    let error_fields = Object.assign([], this.state.error_fields),
      show = Object.assign([], this.state.show),
      redirect = this.state.redirect,
      _find,
      redirect_switch = this.state.redirect_switch,
      clear = false;

    if (value && typeof value === "string" && value.indexOf("r-switch") > -1) {
      redirect_switch = value.split("::")[0].replace("r-switch:", "");
      console.log("redirect_switch", redirect_switch);
    }

    if (typeof value === "string" && value.indexOf("redirect") > -1) {
      redirect = value.split("::")[0].replace("redirect:", "");
    }
    // if(typeof value.getMonth === 'function') value = moment(value).format('YYYY-MM-DD');

    if (!data[index]) data[index] = { name: name, value: [] };

    if (multi) {
      console.log("data new index");

      if (
        !data[index] ||
        typeof data[index] !== "object" ||
        typeof data[index].value !== "object"
      )
        data[index] = { name: name, value: [] };

      if (new_value) {
        data[index].value.push(value);
      } else {
        _find = data[index].value.indexOf(value);
        data[index].value.splice(_find, 1);
      }
    } else {
      data[index].value = value;
    }

    if (actions) {
      actions.forEach(function (item) {
        if (item.action === "hide") {
          show[item.target] = false;
          //error_fields[target] = null;
        } else {
          show[item.target] = true;
        }
      });
    }
    if (error_fields[index] && error_fields[index].length)
      error_fields[index] = this.validateField(field, data[index]);

    if (existing_data) return data;

    this.setState({
      data: data,
      error_fields: error_fields,
      show: show,
      redirect: redirect,
      redirect_switch: redirect_switch,
    });
  }
  showStep(step) {
    let fields = [];
    let that = this;
    let className;
    //fields.push(<h3>{step.title}</h3>);

    step.blocks.forEach(function (block, __index) {
      let optional =
        block.required === "false" && !block.no_label === "true"
          ? '<span class="optional"> - optional</span>'
          : "";
      if (site.show_required && block.required === "true") {
        optional = '<span class="optional"> - required</span>';
      }
      let index = that.stepIndex(that.state.step, __index);
      let value = that.state.data[index] ? that.state.data[index].value : "";
      let name = "input-" + index;
      let conditional_group = block.conditional_group
        ? block.conditional_group.split(",")
        : null;

      className =
        that.state.error_fields[index] && that.state.error_fields[index].length
          ? "error"
          : "";
      if (
        block.only_for_price &&
        block.only_for_price !== localStorage.getItem("emr-price")
      )
        return;
      if (conditional_group)
        conditional_group.forEach(function (condition) {
          if (!that.state.show[condition]) {
            className += " hide";
          }
        });
      if (block.className) className += " " + block.className;
      if (block.matches) className += " match-" + block.matches;

      if (block.validate == "phone") {
        fields.push(
          <label
            key={index}
            className={className}
            dangerouslySetInnerHTML={{
              __html: that.blockTitle(block) + optional,
            }}
          />
        );
        fields.push(
          <Cleave
            options={{
              phone: true,
              delimiter: "-",
              phoneRegionCode: "US",
            }}
            placeholder="###-###-####"
            key={200 + index}
            className={className}
            value={value}
            onChange={(e) => {
              that.update(block, that.blockTitle(block), index, e.target.value);
            }}
            type={block.type}
            name={name}
            required={block.required === "true" ? "required" : ""}
          />
        );
      } else if (["text", "email", "password"].indexOf(block.type) !== -1) {
        fields.push(
          <label
            key={index}
            className={className}
            dangerouslySetInnerHTML={{
              __html: that.blockTitle(block) + optional,
            }}
          />
        );
        if (block.no_paste && block.no_paste === "true")
          fields.push(
            <input
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
              autoComplete={false}
              data-must-match={block.matches}
              key={200 + index}
              className={className}
              value={value}
              onChange={(e) => {
                that.update(
                  block,
                  that.blockTitle(block),
                  index,
                  e.target.value
                );
              }}
              type={block.type}
              name={name}
              required={block.required === "true" ? "required" : ""}
            />
          );
        else
          fields.push(
            <input
              data-must-match={block.matches}
              key={200 + index}
              className={className}
              value={value}
              onChange={(e) => {
                that.update(
                  block,
                  that.blockTitle(block),
                  index,
                  e.target.value
                );
              }}
              type={block.type}
              name={name}
              disabled={block.type === "password" && that.state.account_id}
              required={block.required === "true" ? "required" : ""}
            />
          );
      } else if (["textarea"].indexOf(block.type) !== -1) {
        fields.push(
          <label
            key={index}
            className={className}
            dangerouslySetInnerHTML={{
              __html: that.blockTitle(block) + optional,
            }}
          />
        );
        fields.push(
          <textarea
            data-must-match={block.matches}
            key={200 + index}
            className={className}
            value={value}
            onChange={(e) => {
              that.update(block, that.blockTitle(block), index, e.target.value);
            }}
            type={block.type}
            name={name}
            required={block.required === "true" ? "required" : ""}
          />
        );
      }
      if (["date"].indexOf(block.type) !== -1) {
        fields.push(
          <label
            key={index}
            className={className}
            dangerouslySetInnerHTML={{
              __html: that.blockTitle(block) + optional,
            }}
          />
        );
        fields.push(
          <Cleave
            options={{
              date: true,
              datePattern: ["m", "d", "Y"],
            }}
            placeholder="MM/DD/YYYY"
            key={200 + index}
            className={className}
            value={moment(value).tz(site.timezone).format("MM/DD/YYYY")}
            selected={value}
            onBlur={(e) => {
              let date = moment(e.target.value).hour(10).format();
              that.update(block, that.blockTitle(block), index, date);
            }}
          />
        );
      }
      if (block.type === "hidden") {
        fields.push(
          <input
            key={index}
            className={className}
            value={value}
            onChange={(e) => {
              that.update(block, that.blockTitle(block), index, e.target.value);
            }}
            type="hidden"
            name={name}
          />
        );
      }
      if (block.type === "paragraph") {
        fields.push(
          <div
            key={index}
            className={`paragraph${className}`}
            dangerouslySetInnerHTML={{ __html: block.text }}
          />
        );
      }
      if (block.type === "select") {
        fields.push(
          <label
            key={index}
            className={className}
            dangerouslySetInnerHTML={{
              __html: that.blockTitle(block) + optional,
            }}
          />
        );
        let options = [
          <option key="0" value={block.placeholder}>
            {block.placeholder}
          </option>,
        ];
        block.elements.forEach(function (option, _index) {
          options.push(
            <option key={_index + 1} value={option}>
              {option}
            </option>
          );
        });
        fields.push(
          <select
            key={200 + index}
            className={className}
            value={value}
            onChange={(e) => {
              that.update(block, that.blockTitle(block), index, e.target.value);
            }}
            name={name}
            required={block.required === "true" ? "required" : ""}
          >
            {options}
          </select>
        );
      }
      if (block.type === "radio") {
        if (
          localStorage.getItem("emr-price") === "membership" &&
          block.member_hide === "true"
        ) {
          return;
        }
        if (
          ["wlc", "chronic-pain", "headache", "ora"].indexOf(
            localStorage.getItem("emr-price")
          ) > -1 &&
          block.price_hide === "true"
        ) {
          return;
        }
        let header,
          options = [],
          type = block.multichoice === "true" ? "checkbox" : "radio";

        block.elements.forEach(function (element, _index) {
          let value = element.value,
            label = element.value,
            split,
            actions;

          if (element.type !== "header") {
            if (value.indexOf("::") > -1) {
              split = value.split("::");
              //value = split[0];
              label = split[1];
            }
            if (element.condition) {
              split = element.condition.split(",");
              actions = split.map(function (item) {
                var split = item.split(":");
                return { action: split[0], target: split[1] };
              });
            }
            if (!label || typeof label === "undefined") label = "";

            options.push(
              <div
                key={_index}
                className={`option ${element.disabled ? " disabled" : ""}`}
              >
                <input
                  disabled={element.disabled ? "disabled" : ""}
                  checked={
                    that.state.data[index] &&
                    that.state.data[index].value &&
                    that.state.data[index].value.indexOf(value) > -1
                      ? "checked"
                      : ""
                  }
                  onChange={(e) => {
                    type === "checkbox"
                      ? that.update(
                          block,
                          that.blockTitle(block),
                          index,
                          e.target.value,
                          true,
                          e.target.checked,
                          actions
                        )
                      : that.update(
                          block,
                          that.blockTitle(block),
                          index,
                          e.target.value,
                          false,
                          true,
                          actions
                        );
                  }}
                  name={name}
                  type={type}
                  value={value}
                />
                <label
                  className="option-label"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              </div>
            );
          }
        });
        if (that.blockTitle(block).trim() !== "")
          fields.push(
            <label
              key={index}
              className={`radio ${className}`}
              dangerouslySetInnerHTML={{
                __html: that.blockTitle(block) + optional,
              }}
            />
          );
        fields.push(
          <div key={200 + index} className={`options ${className}`}>
            {options}
          </div>
        );
      }
    });
    return fields;
  }
}

export default Form;
